import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import Logo from './Logo';

export default function Login(props: { theme: any }) {
  const [email, updateEmail] = useState<string>('');
  const [setEmail, updateSetEmail] = useState<boolean>(false);
  const [password, updatePassword] = useState<string>('');
  const [setPassword, updateSetPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const alert = {
    welcome: '',
    submitting: 'Submitting...',
    success: `You are now logged in`,
    error: 'Something is not right. Correct the fields below and try again.',
  };

  const [message, setMessage] = useState<string>(alert.welcome);

  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY || '');

    if (token) {
      const decoded: JwtPayload & { exp: Date } = jwtDecode(token);
      if (decoded.exp) {
        if (new Date(decoded.exp * 1000) > new Date())
          window.location.href = '/account';
      }
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email');
    const password = data.get('password');

    if (!email || !password) {
      setMessage(alert.error);
      updateSetEmail(true);
      updateSetPassword(true);
    } else {
      setMessage(alert.submitting);
      setLoading(true);
      setError(false);
      fetch(`${process.env.REACT_APP_API}/auth/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode > 201) throw new Error(data.message);
          return data;
        })
        .then((user) => {
          localStorage.setItem(
            process.env.REACT_APP_TOKEN_KEY || '',
            user.accessToken
          );
          setLoading(false);
          setMessage(alert.success);
          window.location.href = '/account';
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
          setMessage(error.message);
          console.error(error);
        });
    }
  };

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <Container component={'main'}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo height={403} width={217} />
          {/*<Typography variant={'h2'} component={'h1'}>*/}
          {/*  Login to Beta*/}
          {/*</Typography>*/}
          <Typography
            variant={'subtitle1'}
            component={'p'}
            color={
              message === alert.error || error
                ? props.theme.palette.error.main
                : props.theme.palette.text.primary
            }
          >
            {loading && <CircularProgress />}
            {message}
          </Typography>
          {/*<Typography variant={'subtitle2'} component={'h3'}>*/}
          {/*  {`Number of spots remaining: ${remainingSeats}`}*/}
          {/*</Typography>*/}
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={email}
                  onChange={(e) => {
                    updateEmail(e.target.value);
                    updateSetEmail(true);
                  }}
                  error={email === '' && setEmail}
                  helperText={
                    email === '' && setEmail ? 'Email is required' : ''
                  }
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={password}
                  onChange={(e) => {
                    updatePassword(e.target.value);
                    updateSetPassword(true);
                  }}
                  error={password === '' && setPassword}
                  helperText={
                    password === '' && setPassword ? 'Password is required' : ''
                  }
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              Login
            </Button>
            <Grid container justifyContent={'space-between'}>
              <Link href={'/password/reset/request'}>Forgot password?</Link>
              {/*<Link href={'/register'}>Sign up</Link>*/}
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
