import React from 'react';
import './footer.css';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Link,
  ThemeProvider,
} from '@mui/material';
import { Header } from './Header';
import Videos from './Videos';
import { HeaderType } from './enums';

export const About = (props: { theme: any }) => {
  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <Header theme={props.theme} headerType={HeaderType.MAIN} />
      <Container
        component={'main'}
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Videos theme={props.theme} />
      </Container>
    </ThemeProvider>
  );
};
