import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Stream from './components/Stream';

export default function AI_Experimental(props: { theme: any }) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mirrorCanvasRef = useRef<HTMLCanvasElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>(
    'This is an experimental build testing new AI that will scale to all users.'
  );
  const [playing, setPlaying] = useState<boolean>(false);
  const [websocket, setWebsocket] = useState<WebSocket>(null);
  const [websocketConnected, setWebsocketConnected] = useState<boolean>(false);
  const sending = useRef<boolean>(true);
  const startTime = useRef<number>(0);
  const processingTime = useRef<number>(0);
  const sessionStartTime = useRef<number>(0);
  const [sessionTime, setSessionTime] = useState<number>(0);
  const [frontalArea, setFrontalArea] = useState<number>(0);
  const streamData = useRef<Array<{ date: Date; value: number }>>([]);

  useEffect(() => {
    const getCamera = async () => {
      const constraints = { video: true };
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play();
            setPlaying(true);
          };
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    };

    getCamera();
  }, []);

  useEffect(() => {
    // const url = `${process.env.REACT_APP_WS_HOST}:${process.env.REACT_APP_WS_PORT}`;
    // const url = `ws://localhost:8443`;
    const url = `wss://ai-test.aero.chat`;
    // const url = `wss://ai.aero.chat`;
    const ws = new WebSocket(url);

    ws.onopen = () => {
      setWebsocketConnected(true);
      sessionStartTime.current = Date.now();
    };

    ws.onerror = (error) => {
      setErrorMessage(`Server connection error: ${error}`);
    };

    ws.onmessage = (event) => {
      setSessionTime(Date.now() - sessionStartTime.current);
      const data = event.data;
      const imageDataUrl = data.split('?area=')[0];
      const area = data.split('?area=')[1];
      setFrontalArea(area);

      const stream = [...streamData.current];
      stream.push({
        date: new Date(Date.now() + sessionTime / 1000),
        value: area,
      });
      streamData.current = stream
        .slice(-Math.min(stream.length, 60))
        .filter((point) => point.value !== 0);

      if (canvasRef.current) {
        const ctx = mirrorCanvasRef.current.getContext('2d');
        const image = new Image();
        image.onload = () => {
          ctx.clearRect(
            0,
            0,
            mirrorCanvasRef.current.width,
            mirrorCanvasRef.current.height
          );
          ctx.drawImage(
            image,
            0,
            0,
            mirrorCanvasRef.current.width,
            mirrorCanvasRef.current.height
          );
        };
        image.src = imageDataUrl;
      }

      const newProcessingTime = Date.now() - startTime.current;
      const dt = processingTime.current - newProcessingTime;
      processingTime.current = newProcessingTime;
      sending.current = true;

      if (Math.abs(processingTime.current - dt) > 15) {
        setErrorMessage(
          `This is an experimental build testing new AI that will scale to all users. You may see some contours drawn over your image - our AI is building... Server processing time: ${Math.floor(processingTime.current)} ms`
        );
      }
    };

    ws.onclose = () => {
      setWebsocketConnected(false);
      setErrorMessage(
        'Disconnected with the server. Try refreshing your browser.'
      );
    };

    setWebsocket(ws);

    // Close WebSocket connection on component unmount
    return () => {
      ws.close();
      setErrorMessage(
        'Disconnected with the server. Try refreshing your browser.'
      );
    };
  }, []);

  const sendData = (data: string) => {
    if (websocket && websocketConnected && sending.current) {
      sending.current = false;
      startTime.current = Date.now();
      websocket.send(data);
    }
  };

  useEffect(() => {
    if (!playing) return; // Return if not playing
    const ctx = canvasRef.current.getContext('2d');
    const draw = () => {
      if (videoRef.current && canvasRef.current) {
        ctx.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const frame = canvasRef.current.toDataURL('image/jpeg');
        sendData(frame);
        requestAnimationFrame(draw);
      }
    };
    draw();
    return () => {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear canvas on unmount
    };
  }, [playing]); // Trigger redraw when playing changes

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Container component={'main'}>
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <video
            style={{ display: 'none' }}
            ref={videoRef}
            autoPlay
            playsInline
          />
          <canvas
            style={{ display: 'none' }}
            ref={canvasRef}
            width={640} // Set canvas dimensions
            height={480}
          />
          <canvas
            ref={mirrorCanvasRef}
            style={{
              maxWidth: '100%',
              borderRadius: '50%',
              // boxShadow: '5px 5px 5px 5px #00FF00',
            }} // Ensure canvas fits its container
            width={640} // Set canvas dimensions
            height={480}
          />
          <Typography>{`Session Time: ${Math.floor(sessionTime / 1000)} s`}</Typography>
          {/*<Typography>{`${frontalArea}`}</Typography>*/}
        </Box>
      </Container>
      <Stream data={streamData.current} width={'100%'} />
    </ThemeProvider>
  );
}
