import React from 'react';
import './footer.css';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CssBaseline,
  IconButton,
  ThemeProvider,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

enum socialMedia {
  instagram = 'https://www.instagram.com/aero.chat',
  x = 'https://twitter.com/aero_chat',
}

export const Footer = (props: { theme: any }) => {
  const navigateTo = (path: string) => {
    window.location.href = path;
  };

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      {/*<div className={'copyright'}>&copy; 2024 AeroChat Inc.</div>*/}
      {/*<div className={'patent'}>Patent Pending</div>*/}
      {/*<BottomNavigation*/}
      {/*// showLabels*/}
      {/*// value={value}*/}
      {/*// onChange={(event, newValue) => {*/}
      {/*//     setValue(newValue);*/}
      {/*// }}*/}
      {/*>*/}
      {/*  <BottomNavigationAction label="Instagram" icon={<InstagramIcon />} />*/}
      {/*  <BottomNavigationAction label="X" icon={<XIcon />} />*/}
      {/*  <div>&copy; 2024 AeroChat Inc.</div>*/}
      {/*  <div>Patent Pending</div>*/}
      {/*</BottomNavigation>*/}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginTop: 'auto',
          p: 1,
          background: '#000',
        }}
        component="footer"
      >
        <div>&copy; 2024 AeroChat Inc.</div>
        <IconButton
          name={'instagram'}
          color={'secondary'}
          onClick={() => navigateTo(socialMedia.instagram)}
        >
          <InstagramIcon />
        </IconButton>

        <IconButton
          name={'x'}
          color={'secondary'}
          onClick={() => navigateTo(socialMedia.x)}
        >
          <XIcon />
        </IconButton>

        <div>Patent Pending</div>
      </Box>
    </ThemeProvider>
  );
};
