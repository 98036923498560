import React, { useEffect, useRef } from 'react';
import { Chat } from './Chat';
import { Footer } from './Footer';
import './app.css';
import { Homepage } from './Homepage';
import { About } from './About';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Analysis from './Analysis';
import Register from './Register';
import { createTheme } from '@mui/material/styles';
import Verify from './Verify';
import Login from './Login';
import PasswordResetRequest from './PasswordResetRequest';
import ResetPassword from './ResetPassword';
import Tunnel from './AI';
import Account from './Account';
import AI_Experimental from './AI_Experimental';
import Videos from './Videos';
import { Dashboard } from './Dashboard';

const theme = createTheme({
  palette: {
    mode: 'dark',
    text: {
      primary: '#6aff45',
      secondary: '#ff00ff',
    },
    error: {
      main: '#ff00ff',
    },
    primary: {
      light: '#6aff45',
      main: '#6aff45',
      dark: '#6aff45',
      contrastText: '#000',
    },
    secondary: {
      light: '#ff00ff',
      main: '#ff00ff',
      dark: '#ff00ff',
      contrastText: '#000',
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage theme={theme} />,
  },
  {
    path: '/about',
    element: <About theme={theme} />,
  },
  {
    path: '/videos',
    element: <About theme={theme} />,
  },
  // {
  //   path: '/register',
  //   element: <Register theme={theme} />,
  // },
  {
    path: '/login',
    element: <Login theme={theme} />,
  },
  {
    path: '/password/reset/request',
    element: <PasswordResetRequest theme={theme} />,
  },
  {
    path: '/password/reset',
    element: <ResetPassword theme={theme} />,
  },
  {
    path: '/verify/email',
    element: <Verify theme={theme} />,
  },
  {
    path: '/ai',
    element: <Dashboard theme={theme} />,
  },
  {
    path: '/ai-experimental',
    element: <AI_Experimental theme={theme} />,
  },
  {
    path: '/account',
    element: <Account theme={theme} />,
  },
]);

function App() {
  return (
    // <div className={'app'}>
    <RouterProvider router={router} />
    // </div>
  );
}

export default App;
