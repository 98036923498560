import React, { useEffect, useRef, useState } from 'react';
import {
  CssBaseline,
  Stack,
  Switch,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Stream from './components/Stream';
import { Boards } from './enums';

enum ViewMode {
  Video = 'VIDEO',
  AI = 'CONTOURS',
}

export default function AI(props: { theme: any; dashboards: Boards[] }) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mirrorCanvasRef = useRef<HTMLCanvasElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>(
    'This is an experimental build testing new AI that will scale to all users.'
  );
  const [playing, setPlaying] = useState<boolean>(false);
  const websocket = useRef<WebSocket>(null);
  const websocketConnected = useRef<boolean>(false);
  const sending = useRef<boolean>(false);
  const startTime = useRef<number>(0);
  const processingTime = useRef<number>(0);
  const sessionStartTime = useRef<number>(0);
  const [sessionTime, setSessionTime] = useState<number>(0);
  const [frontalArea, setFrontalArea] = useState<number>(0);
  const streamData = useRef<Array<{ date: Date; value: number }>>([]);
  const viewMode = useRef<ViewMode>(ViewMode.Video);
  const segmentation = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const displayStream = useRef<boolean>(false);

  const isMobile = useMediaQuery('(max-width: 600px) or (max-height: 500px)');

  useEffect(() => {
    const playVideo = async () => {
      displayStream.current = true;

      const constraints = { video: true };
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            videoRef.current
              .play()
              .then(() => {
                setPlaying(true);
                sending.current = true;
              })
              .catch((error) => {
                console.error('Error playing video:', error);
                setErrorMessage(error.message);
              });
          };
        }
      } catch (error: any) {
        console.error('Error accessing camera:', error);
        setErrorMessage(error.message);
      }
    };

    playVideo();
  }, []);

  useEffect(() => {
    const url = `wss://ai.aero.chat`;
    const ws = new WebSocket(url);

    ws.onopen = () => {
      console.log('set ws');
      websocketConnected.current = true;
      sessionStartTime.current = Date.now();
    };

    ws.onerror = (error: any) => {
      setErrorMessage(`Server connection error: ${error.message}`);
    };

    ws.onmessage = (event) => {
      setSessionTime(Date.now() - sessionStartTime.current);
      const data = event.data;
      const imageDataUrl = data.split('?area=')[0];
      const area = data.split('?area=')[1];
      setFrontalArea(area);

      const stream = [...streamData.current];
      stream.push({
        date: new Date(Date.now() + sessionTime / 1000),
        value: area,
      });
      streamData.current = stream
        .slice(-Math.min(stream.length, 60))
        .filter((point) => point.value !== 0);

      if (
        props.dashboards.includes(Boards.computerVision) &&
        canvasRef.current
      ) {
        const ctx = mirrorCanvasRef.current.getContext('2d');
        const image = new Image();
        image.onload = () => {
          ctx.clearRect(
            0,
            0,
            mirrorCanvasRef.current.width,
            mirrorCanvasRef.current.height
          );
          ctx.drawImage(
            image,
            0,
            0,
            mirrorCanvasRef.current.width,
            mirrorCanvasRef.current.height
          );
        };
        image.src = imageDataUrl;
      }

      const newProcessingTime = Date.now() - startTime.current;
      const dt = processingTime.current - newProcessingTime;
      processingTime.current = newProcessingTime;
      sending.current = true;

      if (Math.abs(processingTime.current - dt) > 15) {
        setErrorMessage(
          `This is a beta release. Server processing time: ${Math.floor(processingTime.current)} ms`
        );
      }
    };

    ws.onclose = () => {
      websocketConnected.current = false;
      setErrorMessage(
        'Disconnected with the server. Try refreshing your browser.'
      );
    };

    websocket.current = ws;

    return () => {
      ws.close();
      setErrorMessage(
        'Disconnected with the server. Try refreshing your browser.'
      );
    };
  }, []);

  const sendData = (data: string) => {
    if (websocket.current && websocketConnected.current && sending.current) {
      sending.current = false;
      startTime.current = Date.now();
      websocket.current.send(data);
    }
  };

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    const draw = () => {
      if (videoRef.current && canvasRef.current) {
        ctx.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const frame = canvasRef.current.toDataURL('image/jpeg');
        sendData(
          `${frame}?view=${viewMode.current}&segment=${segmentation.current}`
        );
        requestAnimationFrame(draw);
      }
    };
    draw();
    return () => {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear canvas on unmount
    };
  }, []);

  const switchViewMode = () => {
    if (viewMode.current === ViewMode.Video) viewMode.current = ViewMode.AI;
    else viewMode.current = ViewMode.Video;
  };

  const resizeCanvas = () => {
    const canvas = mirrorCanvasRef.current;
    if (!canvas) return;

    const { outerWidth: windowWidth, outerHeight: windowHeight } = window;
    const isLandscape = windowWidth > windowHeight;

    canvas.style.width = isLandscape ? `${windowHeight}px` : `${windowWidth}px`;
    if (!isLandscape) {
      canvas.style.height = `${windowWidth}px`;
    }
  };

  useEffect(() => {
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <video style={{ display: 'none' }} ref={videoRef} autoPlay playsInline />
      <canvas
        style={{ display: 'none' }}
        ref={canvasRef}
        width={640}
        height={480}
      />

      {props.dashboards.includes(Boards.computerVision) && (
        <>
          <canvas
            ref={mirrorCanvasRef}
            width={640}
            height={480}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Video</Typography>
            <Switch
              checked={viewMode.current === ViewMode.AI}
              // onChange={switchViewMode}
              onClick={switchViewMode}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>AI Contours</Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            style={{ padding: '3px' }}
          >
            <Typography>Image Segmentation</Typography>
            <Typography>Off</Typography>
            <Switch
              checked={segmentation.current}
              // onChange={switchViewMode}
              onClick={() => (segmentation.current = !segmentation.current)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>On</Typography>
          </Stack>
        </>
      )}
      {props.dashboards.includes(Boards.dragLine) && (
        <Stream data={streamData.current} width={'100%'} />
      )}
    </ThemeProvider>
  );
}
