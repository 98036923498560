import React from 'react';
// @ts-ignore
import Video from './aero_video.mp4';
import './video.css';
import { Footer } from './Footer';
import {
  Alert,
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import { Chat } from './Chat';
import { Header } from './Header';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HeaderType } from './enums';

enum msg {
  betaRelease = 'Our beta release is live!',
}

export const Homepage = (props: { theme: any }) => {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <Alert severity={'info'} color={'error'}>
        {msg.betaRelease}
      </Alert>
      <Header theme={props.theme} headerType={HeaderType.MAIN} />
      <Container
        component={'main'}
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginTop: 'auto',
            p: 5,
          }}
        >
          <Chat theme={props.theme} sequence={1} />
          <video
            autoPlay
            muted
            loop
            playsInline
            style={{
              padding: '25px',
              background: 'black',
              width: matches ? '50%' : '98%',
              borderRadius: '50%',
            }}
          >
            <source src={Video} type="video/mp4" />
          </video>
        </Box>
        <Footer theme={props.theme} />
      </Container>
    </ThemeProvider>
  );
};
