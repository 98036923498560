import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import Logo from './Logo';

export default function ResetPassword(props: { theme: any }) {
  const [password, updatePassword] = useState<string>('');
  const [setPassword, updateSetPassword] = useState<boolean>(false);
  const [confirmedPassword, updateConfirmedPassword] = useState<string>('');
  const [setConfirmedPassword, updateSetConfirmedPassword] =
    useState<boolean>(false);
  const [expired, updateExpired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const alert = {
    welcome: 'Enter a new password',
    submitting: 'Submitting...',
    success: `Your password has been reset.`,
    error: 'Something is not right. Correct the fields below and try again.',
  };

  const [message, setMessage] = useState<string>(alert.welcome);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const password = data.get('password');

    if (!password || !confirmedPassword) {
      setMessage(alert.error);
      updateSetPassword(true);
      updateSetConfirmedPassword(true);
    } else {
      setMessage(alert.submitting);
      setLoading(true);
      setError(false);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params.token) {
        const decoded: JwtPayload & { email: string } = jwtDecode(params.token);
        if (decoded.exp && new Date(decoded.exp * 1000) < new Date()) {
          setMessage(`This link has expired.`);
          updateExpired(true);
        } else {
          fetch(`${process.env.REACT_APP_API}/auth/password/reset`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${params.token}`,
            },
            body: JSON.stringify({
              password,
            }),
          })
            .then((resp) => resp.json())
            .then((data) => {
              if (data.statusCode > 201) throw new Error(data.message);
              return data;
            })
            .then((user) => {
              console.log(user);
              setLoading(false);
              setMessage(alert.success);
              window.location.href = '/login';
            })
            .catch((error) => {
              setError(true);
              setLoading(false);
              setMessage(error.message);
              console.error(error);
            });
        }
      }
    }
  };

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <Container component={'main'}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo height={403} width={217} />
          <Typography
            variant={'subtitle1'}
            component={'p'}
            color={
              message === alert.error || error
                ? props.theme.palette.error.main
                : props.theme.palette.text.primary
            }
          >
            {loading && <CircularProgress />}
            {message}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={password}
                  onChange={(e) => {
                    updatePassword(e.target.value);
                    updateSetPassword(true);
                  }}
                  error={
                    (password === '' && setPassword) ||
                    (password.length < 8 && setPassword)
                  }
                  helperText={
                    password === '' && setPassword
                      ? 'Password is required'
                      : password.length < 8 && setPassword
                        ? 'Password is too short'
                        : ''
                  }
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={confirmedPassword}
                  onChange={(e) => {
                    updateConfirmedPassword(e.target.value);
                    updateSetConfirmedPassword(true);
                  }}
                  error={
                    (confirmedPassword === '' && setConfirmedPassword) ||
                    (password !== confirmedPassword && setConfirmedPassword)
                  }
                  helperText={
                    confirmedPassword === '' && setConfirmedPassword
                      ? 'Confirm password is required'
                      : password !== confirmedPassword && setConfirmedPassword
                        ? 'Passwords must match'
                        : ''
                  }
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirm Password"
                  type="password"
                  id="confirm-password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
