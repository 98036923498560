import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
} from '@mui/material';
// @ts-ignore
import Video from './bike-setup.mp4';
import { Header } from './Header';

export default function Videos(props: { theme: any }) {
  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <Container component={'main'}>
        <Box>
          <Typography>
            aero.chat is an AI aerodynamics tool that makes aero analysis
            acessible to anyone. It was created by Robby Ketchell, who has
            worked in sports aerodynamics and software for more than 15 years.
            He is the one who helped Eliud Kipchoge run a sub 2-hr marathon and
            has worked with multiple professional cycling teams including Team
            Sky/INEOS Grenadiers, helping them win Tour de France titles and
            World Championships.
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/*<Typography>Intro: Bike Setup</Typography>*/}
          <video
            playsInline
            controls
            style={{
              padding: '25px',
              background: 'black',
              width: '90%',
              borderRadius: '5%',
            }}
          >
            <source src={Video} type="video/mp4" />
          </video>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
