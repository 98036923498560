import React, { useEffect, useState } from 'react';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Logo from './Logo';

export default function Verify(props: { theme: any }) {
  const status = {
    verifying: 'Checking...',
    verified: 'Your email is verified',
    notVerified: 'Something happened and we could not verify your email',
  };

  const [verifyStatus, updateVerifyStatus] = useState<string>(status.verifying);
  const [expired, updateExpired] = useState<boolean>(false);
  const [email, updateEmail] = useState<string>('');
  const [success, updateSuccess] = useState<boolean>(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.token) {
      const decoded: JwtPayload & { email: string } = jwtDecode(params.token);
      if (decoded.exp && new Date(decoded.exp * 1000) < new Date()) {
        if (decoded.email) {
          updateEmail(decoded.email);
          updateVerifyStatus(
            `This link has expired. Click the button to send a new link to ${decoded.email}`
          );
          updateExpired(true);
        } else updateVerifyStatus(status.notVerified);
      } else {
        fetch(`${process.env.REACT_APP_API}/users/verify/email`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${params.token}`,
          },
        })
          .then((resp) => resp.json())
          .then((user) => {
            if (user.verifiedEmail) {
              updateVerifyStatus(status.verified);
              updateSuccess(true);
            } else updateVerifyStatus(user.message);
          })
          .catch((error) => {
            updateVerifyStatus(error.message);
          });
      }
    } else {
      updateVerifyStatus(status.notVerified);
    }
  }, []);

  const resend = () => {
    updateExpired(false);
    fetch(`${process.env.REACT_APP_API}/users/verify/email/${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        updateVerifyStatus(resp.message);
      })
      .catch((error) => {
        updateVerifyStatus(error.message);
      });
  };

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <Container component={'main'}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo height={403} width={217} />
          <Typography component={'h1'}>{verifyStatus}</Typography>
          {expired && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => resend()}
            >
              Resend
            </Button>
          )}
          {success && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => (window.location.href = '/login')}
            >
              Login
            </Button>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
