import React from 'react';
import './footer.css';
import { Box, Button, CssBaseline, Link, ThemeProvider } from '@mui/material';
import Logo from './Logo';
import { HeaderType } from './enums';

enum url {
  home = '/',
  login = '/login',
  about = '/about',
}

export const Header = (props: { theme: any; headerType: HeaderType }) => {
  const navigateTo = (path: string) => {
    window.location.href = path;
  };

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
          marginTop: 'auto',
          p: 5,
          padding: '0 21px',
          margin: 0,
        }}
        component="footer"
      >
        <Box onClick={() => navigateTo(url.home)} style={{ cursor: 'pointer' }}>
          <Logo width={200} height={104} />
        </Box>
        {props.headerType === HeaderType.MAIN && (
          <Box>
            <Link
              href={url.about}
              style={{
                margin: '0 12px',
              }}
            >
              About
            </Link>
            <Button
              onClick={() => navigateTo(url.login)}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100px' }}
            >
              Login
            </Button>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};
