import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Logo from './Logo';

interface User {
  id: number;
  createAt: Date;
  updatedAt: Date;
  isActive: boolean;
  verifiedEmail: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  settings: {
    sendMarketingEmails: boolean;
  };
}

export default function Account(props: { theme: any }) {
  const [user, updateUser] = useState<User | null>();
  const [firstName, updateFirstName] = useState<string>(user?.firstName ?? '');
  const [setFirstName, updateSetFirstName] = useState<boolean>(false);
  const [lastName, updateLastName] = useState<string>(user?.lastName ?? '');
  const [setLastName, updateSetlastName] = useState<boolean>(false);
  const [email, updateEmail] = useState<string>(user?.email ?? '');
  const [setEmail, updateSetEmail] = useState<boolean>(false);
  const [sendEmails, updateSendEmails] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [editAccount, updateEditAccount] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY || '');
    if (!token) window.location.href = '/login';

    fetch(`${process.env.REACT_APP_API}/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((loggedInUser) => {
        updateUser(loggedInUser);
        updateFirstName(loggedInUser.firstName);
        updateLastName(loggedInUser.lastName);
        updateEmail(loggedInUser.email);
        updateSendEmails(loggedInUser.settings.sendMarketingEmails);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        window.location.href = '/login';
      });
  }, []);

  const alert = {
    default:
      'You have been accepted as part of the beta user group. We are working toward a beta release and will update your soon. Below, you can manage your account settings.',
    submitting: 'Submitting...',
    success: `Your account has been updated.`,
    error: 'Something is not right. Correct the fields below and try again.',
    email: `An email was sent to ${email}. Check your email and click on the link to verify it's you. If you don't see it in your inbox, make sure to check your spam or junk folders.`,
  };

  const [message, setMessage] = useState<string>(alert.default);

  const handleVerify = () => {
    setLoading(true);
    setMessage(alert.submitting);
    fetch(`${process.env.REACT_APP_API}/users/verify/email/${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setLoading(false);
        setMessage(resp.message);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error.message);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editAccount) {
      updateEditAccount(true);
      setMessage(alert.default);
      return;
    }

    // setLoading(true);
    const data = new FormData(event.currentTarget);

    const firstName = data.get('firstName');
    const lastName = data.get('lastName');
    const sendEmails = data.get('sendEmails');

    if (!firstName || !lastName) {
      setMessage(alert.error);
      updateSetFirstName(true);
      updateSetlastName(true);
      updateSetEmail(true);
    } else {
      setMessage(alert.submitting);
      setLoading(true);
      setError(false);
      fetch(`${process.env.REACT_APP_API}/users/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN_KEY || '')}`,
        },
        body: JSON.stringify({
          firstName,
          lastName,
          settings: {
            sendMarketingEmails: sendEmails === 'send',
          },
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode > 200) throw new Error(data.message);
          return data;
        })
        .then((updatedUser) => {
          console.log(updatedUser);
          setLoading(false);
          updateFirstName(updatedUser.firstName);
          updateLastName(updatedUser.lastName);
          updateEmail(updatedUser.email);
          updateSendEmails(updatedUser.settings.sendMarketingEmails);
          setMessage(alert.success);
          updateEditAccount(false);
          // setLoading(false);
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
          setMessage(error.message);
          console.error(error);
        });
    }
  };

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <Container component={'main'}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo height={403} width={217} />
          <Typography
            variant={'subtitle1'}
            component={'p'}
            color={
              message === alert.error || error
                ? props.theme.palette.error.main
                : props.theme.palette.text.primary
            }
          >
            {loading && <CircularProgress />}
            {message}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              {editAccount && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={firstName}
                      onChange={(e) => {
                        updateFirstName(e.target.value);
                        updateSetFirstName(true);
                      }}
                      error={firstName === '' && setFirstName}
                      helperText={
                        firstName === '' && setFirstName
                          ? 'First name is required'
                          : ''
                      }
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      value={lastName}
                      onChange={(e) => {
                        updateLastName(e.target.value);
                        updateSetlastName(true);
                      }}
                      error={lastName === '' && setLastName}
                      helperText={
                        lastName === '' && setLastName
                          ? 'Last name is required'
                          : ''
                      }
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={sendEmails}
                          name="sendEmails"
                          value="send"
                          color="primary"
                        />
                      }
                      label="It's OK to email me with updates."
                    />
                  </Grid>
                </>
              )}
              {!editAccount && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h4">{`${firstName} ${lastName}`}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{`${email}`}</Typography>
                  </Grid>
                  {!user?.verifiedEmail && !loading && (
                    <Grid item xs={3}>
                      <Link href={'#'} onClick={handleVerify}>
                        Verify
                      </Link>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendEmails}
                          name="sendEmails"
                          value="send"
                          color="primary"
                          disabled
                        />
                      }
                      label="It's OK to email me with updates."
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
              // onClick={() => updateEditAccount(!editAccount)}
            >
              {editAccount ? 'Save' : 'Edit'}
            </Button>
            <Grid item xs={12}>
              <Link
                href={'/login'}
                onClick={() => {
                  localStorage.removeItem(
                    process.env.REACT_APP_TOKEN_KEY || ''
                  );
                }}
              >
                Logout
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link href={'/ai'}>AI aero session (beta)</Link>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
