import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Typography } from '@mui/material';

const introSequence = [
  1500,
  'Welcome to aero.chat',
  1000,
  'I can help you work on your aerodynamics',
  1000,
  'I will help you become a faster cyclist',
  1000,
  'I will help you become a faster runner',
  1000,
  'I will help you become a faster skier',
  1000,
  'I was built by Robby Ketchell, who has worked in sports aerodynamics and software for more than 15 years',
  1000,
  "He's the one who helped Eliud Kipchoge run a sub 2-hour marathon",
  1000,
  'And helped many professional cyclists optimize their positions and equipment for aerodynamics',
  1000,
  'Which has helped them win World Championships and Tour de France titles',
  1000,
  'He recently submitted a patent about me and how my AI is generated',
  1000,
  'I use your camera on your computer, phone, or tablet',
  1000,
  "Let's try it",
  1000,
  'Go ahead and get into an aero tuck by getting into a lower position',
  1000,
  'I will dynamically calibrate your camera',
  1000,
  'At the same time, I will begin analyzing your position',
  1000,
  'You can use me as a tool while riding your bike indoors',
  1000,
  'You can use me as a tool while running indoors',
  1000,
  'Or by just standing there in an aero position',
  1000,
  'I can tell you your CdA',
  1000,
  'I can tell you how fast you will go at a given power output on your bike',
  1000,
  'I can tell you how much energy you will expend in your position',
  1000,
  'I can tell you how your drag changes over time',
  1000,
  'While you move I will give you advice',
  1000,
  'Things like',
  1000,
  'Lower your head',
  1000,
  'Rotate your shoulders',
  1000,
  "See how long you can hold this position, it's really aero",
  1000,
  'Try to limit your movement it is causing extra drag',
  1000,
  'Your current position is 21 Watts lower drag than your average drag',
  1000,
  'The more you use me, the more I can help',
  1000,
  'Because I will recognize your trends',
  1000,
  'And then I can provide more personalized advice',
  1000,
  'Here is an example, watch as I coach this cyclist',
  1000,
  'Try a new position',
  3000,
  'That looks like a new position, keep riding for a bit while I crunch some numbers...',
  3000,
  'This position is better than the previous by 11 Watts at 35 kph',
  1000,
  'This position is better than the previous by 11 Watts at 22 mph',
  1000,
  'This position is better than the previous by 32 Watts at 50 kph',
  1000,
  'This position is better than the previous by 32 Watts at 31 mph',
  1000,
  'Try rotating your shoulders and lowering your head',
  2000,
  "That's it, you just made another improvement by 4 Watts at 22 mph",
  1000,
  'If you create an account and we work together more, I can tell you more things',
  1000,
  'Like',
  1000,
  'How your position trends as you ride in it over time',
  1000,
  'In other words, how long can you hold a position',
  1000,
  'What is the best position for the duration of an effort',
  1000,
  'And much more',
  1000,
  'Our beta has launched! follow us on social media for updates.',
  2000,
];

const dashboardDemoSequence = [
  1000,
  'Lower your head',
  1000,
  'Rotate your shoulders',
  1000,
  "See how long you can hold this position, it's really aero",
  1000,
  'Try to limit your movement it is causing extra drag',
  1000,
  'Your current position is 21 Watts lower drag than your average drag',
  1000,
  'Try a new position',
  3000,
  'That looks like a new position, keep riding for a bit while I crunch some numbers...',
  3000,
  'This position is better than the previous by 11 Watts at 35 kph',
  1000,
  'This position is better than the previous by 11 Watts at 22 mph',
  1000,
  'This position is better than the previous by 32 Watts at 50 kph',
  1000,
  'This position is better than the previous by 32 Watts at 31 mph',
  1000,
  'Try rotating your shoulders and lowering your head',
  2000,
  "That's it, you just made another improvement by 4 Watts at 22 mph",
  1000,
];

export const Chat = (props: { theme: any; sequence: number }) => {
  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '12px',
        height: props.sequence === 1 ? '100%' : 'auto',
        width: '350px',
        margin: '21px',
        minHeight: '280px',
        background: '#000',
      }}
    >
      <TypeAnimation
        sequence={props.sequence === 1 ? introSequence : dashboardDemoSequence}
        wrapper="span"
        speed={50}
        deletionSpeed={99}
        style={{
          zIndex: 2,
          background: '#000',
          fontSize: props.sequence === 1 ? '2em' : '1em',
          display: 'inline-block',
          padding: '21px',
          width: '100%',
          color:
            props.sequence === 1
              ? props.theme.palette.secondary.dark
              : props.theme.palette.primary.dark,
          borderRadius: '12px',
        }}
        repeat={Infinity}
      />
    </div>
  );
};
