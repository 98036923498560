import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Logo from './Logo';

export default function PasswordResetRequest(props: { theme: any }) {
  const [email, updateEmail] = useState<string>('');
  const [setEmail, updateSetEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const alert = {
    welcome:
      'Enter the email for your account and a password request will be sent.',
    submitting: 'Sending...',
    success: `An email was sent to ${email}. If you don't see it in your inbox, make sure to check your spam and junk folders.`,
    error: 'Something is not right. Correct the fields below and try again.',
  };

  const [message, setMessage] = useState<string>(alert.welcome);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email');

    if (!email) {
      setMessage(alert.error);
      updateSetEmail(true);
    } else {
      setMessage(alert.submitting);
      setLoading(true);
      setError(false);
      fetch(`${process.env.REACT_APP_API}/auth/password/reset/request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode > 201) throw new Error(data.message);
          return data;
        })
        .then((user) => {
          console.log(user);
          setLoading(false);
          setMessage(alert.success);
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
          setMessage(error.message);
          console.error(error);
        });
    }
  };

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      <Container component={'main'}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo height={403} width={217} />
          <Typography
            variant={'subtitle1'}
            component={'p'}
            color={
              message === alert.error || error
                ? props.theme.palette.error.main
                : props.theme.palette.text.primary
            }
          >
            {loading && <CircularProgress />}
            {message}
          </Typography>
          <Box
            marginTop={4}
            component="form"
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                  value={email}
                  onChange={(e) => {
                    updateEmail(e.target.value);
                    updateSetEmail(true);
                  }}
                  error={email === '' && setEmail}
                  helperText={
                    email === '' && setEmail ? 'Email is required' : ''
                  }
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <TextField*/}
              {/*    value={email}*/}
              {/*    onChange={(e) => {*/}
              {/*      updateEmail(e.target.value);*/}
              {/*      updateSetEmail(true);*/}
              {/*    }}*/}
              {/*    error={email === '' && setEmail}*/}
              {/*    helperText={*/}
              {/*      email === '' && setEmail ? 'Email is required' : ''*/}
              {/*    }*/}
              {/*    required*/}
              {/*    fullWidth*/}
              {/*    id="email"*/}
              {/*    label="Email Address"*/}
              {/*    name="email"*/}
              {/*    autoComplete="email"*/}
              {/*  />*/}
              {/*</Grid>*/}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
