import React from 'react';

const Logo = (props: { width: number; height: number }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox={`0 0 403 217`}
      xmlSpace="preserve"
    >
      <style>
        {`
          .st0{fill:#6AFF45;stroke:#6AFF45;stroke-width:2.3206;stroke-miterlimit:10;}
          .st1{fill:#FF00FF;stroke:#FF00FF;stroke-width:2.3206;stroke-miterlimit:10;}
          .st2{fill:url(#SVGID_1_);}
          .st3{fill:url(#SVGID_00000144338231788655287200000000994935187469654957_);}
          .st4{fill:url(#SVGID_00000082368665965864911490000003879615561249221287_);stroke:#000000;stroke-width:2.1363;stroke-miterlimit:10;}
        `}
      </style>
      <g id="Layer_2">
        <g>
          <g>
            <path
              className="st0"
              d="M96.1,163.5h-1.4v-8.6c-0.8,1.7-1.8,3.2-3,4.3c-1.1,1.2-2.4,2.2-3.6,2.9c-1.3,0.7-2.6,1.3-4,1.6
				s-2.7,0.5-3.9,0.5c-2.2,0-4.3-0.4-6.3-1.3c-1.9-0.8-3.6-2-5.1-3.4s-2.6-3.1-3.4-5.1c-0.8-1.9-1.3-4-1.3-6.3s0.4-4.3,1.3-6.3
				c0.8-1.9,2-3.6,3.4-5.1s3.1-2.6,5.1-3.4c1.9-0.8,4-1.3,6.3-1.3c1.3,0,2.6,0.2,4,0.5c1.4,0.4,2.7,0.9,4,1.6
				c1.3,0.7,2.5,1.7,3.6,2.9c1.1,1.2,2.1,2.6,2.9,4.2v-8.5h1.4V163.5z M94.7,148.2c0-2-0.4-3.8-1.1-5.7c-0.8-1.8-1.8-3.4-3.1-4.7
				s-2.9-2.4-4.6-3.2c-1.8-0.8-3.7-1.2-5.6-1.2c-2,0-3.9,0.4-5.7,1.2c-1.8,0.8-3.3,1.9-4.7,3.2s-2.4,2.9-3.1,4.7
				c-0.8,1.8-1.2,3.7-1.2,5.7c0,2,0.4,3.8,1.2,5.6c0.8,1.8,1.8,3.4,3.1,4.7c1.3,1.4,2.9,2.5,4.6,3.3c1.8,0.8,3.7,1.2,5.7,1.2
				c2,0,3.9-0.4,5.7-1.2c1.8-0.8,3.3-1.9,4.6-3.3c1.3-1.4,2.4-2.9,3.1-4.7C94.3,152,94.7,150.2,94.7,148.2z"
            />
            <path
              className="st0"
              d="M134.4,148.1v0.6h-30.6c0.1,1.9,0.6,3.8,1.4,5.5c0.8,1.7,1.8,3.3,3.1,4.6c1.3,1.3,2.8,2.3,4.5,3.1
				c1.7,0.8,3.6,1.1,5.6,1.1c1.7,0,3.4-0.3,4.9-0.9c1.5-0.6,3-1.5,4.3-2.6l1,1c-1.4,1.2-3.1,2.1-4.8,2.8s-3.6,1-5.4,1
				c-2.2,0-4.3-0.4-6.3-1.3c-1.9-0.9-3.6-2-5.1-3.5s-2.6-3.1-3.5-5.1c-0.9-1.9-1.3-4-1.3-6.2c0-2.2,0.4-4.3,1.3-6.3
				c0.8-1.9,2-3.6,3.4-5.1c1.5-1.4,3.2-2.6,5.1-3.4c2-0.8,4-1.3,6.3-1.3c2.2,0,4.3,0.4,6.2,1.3c1.9,0.9,3.6,2,5.1,3.5
				c1.5,1.5,2.6,3.2,3.4,5.1C134,143.9,134.4,146,134.4,148.1z M133,147.5c-0.1-1.9-0.6-3.7-1.4-5.5c-0.8-1.7-1.8-3.2-3.1-4.5
				c-1.3-1.3-2.8-2.3-4.5-3.1c-1.7-0.8-3.6-1.1-5.5-1.1c-2,0-3.9,0.4-5.6,1.2c-1.7,0.8-3.2,1.8-4.6,3.1c-1.3,1.3-2.4,2.8-3.2,4.5
				c-0.8,1.7-1.2,3.5-1.3,5.4H133z"
            />
            <path
              className="st0"
              d="M164.7,136.2l-1,1.1c-0.5-0.5-1.1-1.1-1.8-1.5c-0.7-0.5-1.5-0.9-2.2-1.3c-0.8-0.4-1.6-0.6-2.5-0.8
				c-0.9-0.2-1.7-0.3-2.5-0.3c-1.2,0-2.6,0.3-4,0.8c-1.4,0.5-2.8,1.3-4.1,2.2c-1.3,1-2.3,2.2-3.2,3.7c-0.9,1.5-1.3,3.2-1.3,5.1
				l0,18.4h-1.4v-30.6h1.4v6.8c0.7-1.3,1.6-2.5,2.6-3.4c1-0.9,2.1-1.7,3.2-2.3c1.1-0.6,2.3-1.1,3.5-1.4c1.2-0.3,2.3-0.4,3.4-0.4
				c2.1,0,4,0.4,5.7,1.1C162,134,163.5,135,164.7,136.2z"
            />
            <path
              className="st0"
              d="M198.7,148.2c0,2.2-0.4,4.3-1.3,6.3c-0.8,1.9-2,3.6-3.4,5.1c-1.5,1.4-3.1,2.6-5.1,3.4
				c-1.9,0.8-4,1.3-6.2,1.3c-2.2,0-4.3-0.4-6.3-1.3s-3.7-2-5.1-3.4c-1.5-1.4-2.6-3.1-3.4-5.1c-0.8-1.9-1.3-4-1.3-6.3
				s0.4-4.3,1.3-6.3c0.8-1.9,2-3.6,3.4-5.1c1.5-1.4,3.2-2.6,5.1-3.4c2-0.8,4-1.3,6.3-1.3c2.2,0,4.3,0.4,6.2,1.3
				c1.9,0.8,3.6,2,5.1,3.4c1.5,1.4,2.6,3.1,3.4,5.1C198.3,143.9,198.7,146,198.7,148.2z M197.2,148.2c0-2-0.4-3.8-1.2-5.7
				c-0.8-1.8-1.8-3.4-3.1-4.7s-2.9-2.4-4.6-3.2c-1.8-0.8-3.7-1.2-5.6-1.2c-2,0-3.9,0.4-5.7,1.2c-1.8,0.8-3.3,1.9-4.7,3.2
				s-2.4,2.9-3.1,4.7c-0.8,1.8-1.2,3.7-1.2,5.7c0,2,0.4,3.8,1.2,5.6c0.8,1.8,1.8,3.4,3.1,4.7c1.3,1.4,2.9,2.5,4.6,3.3
				c1.8,0.8,3.7,1.2,5.7,1.2c2,0,3.9-0.4,5.6-1.2c1.8-0.8,3.3-1.9,4.6-3.3c1.3-1.4,2.4-2.9,3.1-4.7
				C196.9,152,197.2,150.2,197.2,148.2z"
            />
            <path
              className="st1"
              d="M207.8,161.6c0,0.6-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.5-0.7-1-0.7-1.6
				c0-0.6,0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7s1.2,0.2,1.6,0.7S207.8,160.9,207.8,161.6z"
            />
            <path
              className="st1"
              d="M240.4,159.2c-0.7,0.8-1.5,1.5-2.4,2.2s-1.8,1.2-2.8,1.6c-1,0.4-2,0.7-3,1c-1,0.2-2.1,0.3-3.1,0.3
				c-2.3,0-4.5-0.4-6.5-1.3s-3.7-2-5.2-3.4s-2.6-3.1-3.4-5.1c-0.8-1.9-1.2-4-1.2-6.3s0.4-4.3,1.3-6.3c0.8-1.9,2-3.6,3.4-5.1
				c1.4-1.4,3.2-2.6,5.1-3.4c2-0.8,4.1-1.3,6.5-1.3c2.2,0,4.2,0.4,6.1,1.3c1.9,0.9,3.6,2.1,5.2,3.6l-1,1c-1.4-1.4-2.9-2.6-4.7-3.4
				c-1.8-0.8-3.6-1.3-5.6-1.3c-2.1,0-4.1,0.4-5.9,1.2c-1.8,0.8-3.4,1.9-4.7,3.2c-1.3,1.4-2.4,2.9-3.1,4.7c-0.8,1.8-1.1,3.7-1.1,5.7
				c0,2,0.4,3.8,1.1,5.6c0.8,1.8,1.8,3.4,3.1,4.7c1.3,1.4,2.9,2.5,4.7,3.3c1.8,0.8,3.8,1.2,5.9,1.2c0.9,0,1.8-0.1,2.8-0.4
				c1-0.2,1.9-0.6,2.9-1c0.9-0.4,1.8-0.9,2.6-1.5c0.8-0.6,1.5-1.2,2-2L240.4,159.2z"
            />
            <path
              className="st1"
              d="M273.7,145.6v17.9h-1.5v-17.7c0-1.8-0.3-3.4-1-4.9s-1.5-2.8-2.6-3.9c-1.1-1.1-2.4-2-3.9-2.6
				c-1.5-0.6-3.1-0.9-4.9-0.9c-1.6,0-3.2,0.3-4.7,0.9c-1.5,0.6-2.9,1.5-4,2.6c-1.2,1.1-2.1,2.4-2.8,3.9s-1,3.1-1,4.9l0,17.8h-1.4
				v-41.3h1.4v17.2c2-2.6,4-4.5,6-5.6c2-1.1,4.2-1.6,6.6-1.6c2,0,3.9,0.3,5.6,1c1.7,0.7,3.2,1.6,4.4,2.9s2.2,2.6,2.8,4.3
				C273.4,142,273.7,143.7,273.7,145.6z"
            />
            <path
              className="st1"
              d="M311.7,163.5h-1.4v-8.6c-0.8,1.7-1.8,3.2-3,4.3c-1.1,1.2-2.4,2.2-3.6,2.9c-1.3,0.7-2.6,1.3-4,1.6
				c-1.4,0.3-2.7,0.5-3.9,0.5c-2.2,0-4.3-0.4-6.3-1.3s-3.6-2-5.1-3.4c-1.5-1.4-2.6-3.1-3.4-5.1c-0.8-1.9-1.3-4-1.3-6.3
				s0.4-4.3,1.3-6.3c0.8-1.9,2-3.6,3.4-5.1c1.4-1.4,3.1-2.6,5.1-3.4c1.9-0.8,4-1.3,6.3-1.3c1.3,0,2.6,0.2,4,0.5
				c1.4,0.4,2.7,0.9,4,1.6c1.3,0.7,2.5,1.7,3.6,2.9c1.1,1.2,2.1,2.6,2.9,4.2v-8.5h1.4V163.5z M310.3,148.2c0-2-0.4-3.8-1.1-5.7
				c-0.8-1.8-1.8-3.4-3.1-4.7s-2.9-2.4-4.6-3.2c-1.8-0.8-3.7-1.2-5.6-1.2c-2,0-3.9,0.4-5.7,1.2c-1.8,0.8-3.3,1.9-4.7,3.2
				s-2.4,2.9-3.1,4.7c-0.8,1.8-1.2,3.7-1.2,5.7c0,2,0.4,3.8,1.2,5.6c0.8,1.8,1.8,3.4,3.1,4.7c1.3,1.4,2.9,2.5,4.6,3.3
				c1.8,0.8,3.7,1.2,5.7,1.2s3.9-0.4,5.7-1.2c1.8-0.8,3.3-1.9,4.6-3.3c1.3-1.4,2.4-2.9,3.1-4.7C309.9,152,310.3,150.2,310.3,148.2z"
            />
            <path
              className="st1"
              d="M337.8,161c-0.8,0.8-1.8,1.6-3.1,2.2c-1.3,0.7-2.6,1-4.1,1c-1.3,0-2.5-0.2-3.7-0.7s-2.2-1.2-3.2-2
				c-0.9-0.9-1.7-1.9-2.2-3.1c-0.6-1.2-0.8-2.5-0.8-3.8v-20.3h-3.4v-1.1h3.4v-5.8l1.5-1.4v7.2h12.5v1.1h-12.5v20.2
				c0,1.3,0.2,2.4,0.7,3.5s1.1,2,1.9,2.7c0.8,0.8,1.7,1.4,2.7,1.8c1,0.4,2.1,0.7,3.2,0.7c0.6,0,1.2-0.1,1.8-0.2s1.2-0.4,1.8-0.7
				c0.5-0.3,1.1-0.6,1.5-0.9s0.9-0.7,1.2-1.1L337.8,161z"
            />
          </g>
          <g>
            <g>
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="19.0948"
                y1="68.7556"
                x2="380.3346"
                y2="68.7556"
              >
                <stop offset="0" style={{ stopColor: '#FF00FF' }} />
                <stop offset="1" style={{ stopColor: '#00FFFF' }} />
              </linearGradient>
              <path
                className="st2"
                d="M78.2,49.5c6.5-1.6,13-2.9,19.5-4.2c2.8-0.6,5.6-1.1,8.5-1.6c45.4-7.5,91.6,0,136.1,11.6
					c46.5,12.1,92.7,27.8,137.8,44c1-1.5-1.2-3.1-2.9-3.7c-37.5-12.5-75.1-25.1-113.1-36.2C218.3,46,170.7,34.6,123.2,39.3
					c-30.3,3-59.3,12.5-89.4,16.2c-1.6,0.2-12.2,1.4-14.6,1.7c0,0-0.1,2,0,2c0,0,3.5-0.4,6.4-0.8c7.4-0.9,20.4-2.1,27.8-3.6
					C61.6,53.1,70,51.5,78.2,49.5z"
              />

              <linearGradient
                id="SVGID_00000052804253395138388030000004967725370314481308_"
                gradientUnits="userSpaceOnUse"
                x1="18.9391"
                y1="84.2342"
                x2="378.3421"
                y2="84.2342"
              >
                <stop offset="0" style={{ stopColor: '#FF00FF' }} />
                <stop offset="1" style={{ stopColor: '#00FFFF' }} />
              </linearGradient>
              <path
                style={{
                  fill: 'url(#SVGID_00000052804253395138388030000004967725370314481308_)',
                }}
                d="M304.5,86.2
					c-30.5-3.1-70-3.1-114.5-3.1c-4.1,0-8.3,0-12.5,0c-50.1,0-79.6-6.2-112.1-13.5c-33-7.4-46.2-6.8-46.4-6.8L19,65
					c0.3,0,13.2-0.6,45.9,6.8c32.6,7.3,62.2,13.5,112.6,13.5c4.3,0,8.5,0,12.5,0c44.4,0,83.9,0,114.2,3.1
					c29.7,3,55.3,10.4,73.3,17.4l0.8-2C360.3,96.6,334.4,89.2,304.5,86.2z"
              />
            </g>

            <linearGradient
              id="SVGID_00000119818890914051449240000017411746178973247105_"
              gradientUnits="userSpaceOnUse"
              x1="83.8343"
              y1="61.9783"
              x2="297.6041"
              y2="61.9783"
            >
              <stop offset="0.5551" style={{ stopColor: '#00FF6D' }} />
              <stop offset="1" style={{ stopColor: '#FA00FF' }} />
            </linearGradient>

            <path
              style={{
                fill: 'url(#SVGID_00000119818890914051449240000017411746178973247105_)',
                strokeWidth: 2.1363,
                strokeMiterlimit: 10,
              }}
              d="
              	M297.6,79.9c0,0-47.6-20.2-95.3-28.7s-57.2-8.4-87.9-5.1S70.1,67,102.5,73.4C135,79.7,202,78,235.4,78.6S297.6,79.9,297.6,79.9z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
