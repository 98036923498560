import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const Stream = ({ data, width }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    // Calculate the new maxY and minY based on the current data
    const currentMaxY = d3.max(data, (d) => d.value);
    const currentMinY = d3.min(data, (d) => d.value);

    const svg = d3.select(svgRef.current);
    const width = 600;
    const height = 300;
    const margin = { top: 20, right: 30, bottom: 30, left: 40 };

    // Clear previous content inside svg
    svg.selectAll('*').remove();

    // Create scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.date))
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([10, 40]) // Adjusted domain to accommodate current data values
      .range([height - margin.bottom, margin.top]);

    // Create line generator
    const line = d3
      .line()
      .defined((d) => !isNaN(d.value))
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))
      .curve(d3.curveCardinal);

    // Append the line path
    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#FF00FF')
      .attr('stroke-width', 1.5)
      .attr('d', line);
  }, [data]);

  return (
    <svg
      ref={svgRef}
      style={{
        position: 'absolute',
        top: 0,
        width,
        height: '30vh',
        marginRight: '0px',
        marginLeft: '0px',
        padding: 0,
      }}
      viewBox="0 0 600 300"
      // preserveAspectRatio="xMidYMid meet"
      preserveAspectRatio="none"
    ></svg>
  );
};

export default Stream;
