import React, { useEffect, useState } from 'react';
import './footer.css';
import {
  Box,
  Button,
  Card,
  Container,
  CssBaseline,
  GlobalStyles,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { Header } from './Header';
import AI from './AI';
// @ts-ignore
import ComputerVision from './aero_video.mp4';
// @ts-ignore
import DragLine from './drag-line.mov';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Boards, HeaderType } from './enums';
import { Chat } from './Chat';

const globalStyles = (
  <GlobalStyles
    styles={{
      '*': {
        scrollbarWidth: 'none' /* Firefox */,
        '&::-webkit-scrollbar': {
          display: 'none' /* Safari and Chrome */,
        },
      },
    }}
  />
);

export const Dashboard = (props: { theme: any }) => {
  // const isDesktop = useMediaQuery('(min-width:600px)');
  const isMobile = useMediaQuery('(max-width: 600px) or (max-height: 500px)');

  const [dash, setDash] = useState<Boards[]>([]);
  const [inSession, setInSession] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY || '');
    if (!token) window.location.href = '/login';

    fetch(`${process.env.REACT_APP_API}/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((loggedInUser) => {})
      .catch((error) => {
        console.error(error);
        window.location.href = '/login';
      });
  }, []);

  const updateDashboards = (board: Boards) => {
    if (!dash.includes(board)) {
      const newDashboard = [...dash, board];
      setDash(newDashboard);
    } else {
      const newDashboard = dash.filter((b) => b !== board);
      setDash(newDashboard);
    }
  };

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline />
      {globalStyles}
      <Box
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          height: '100%',
          overflow: 'scroll',
        }}
      >
        {/*{!isMobile && (*/}
        {/*  <Header theme={props.theme} headerType={HeaderType.SESSION} />*/}
        {/*)}*/}
        <Container
          style={{
            marginTop: 0,
            padding: inSession ? 0 : '21px',
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexFlow: 'column wrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!inSession && (
            <Stack
              style={{
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
                width: '100%',
              }}
            >
              <Typography
                variant={'h5'}
                color={props.theme.palette.text.secondary}
              >
                Choose all the aero tools you want to see in your session and
                then click start
                <Button
                  onClick={() => setInSession(true)}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={dash.length === 0}
                >
                  Start Session
                </Button>
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  justifyContent: 'space-around',
                }}
              >
                <Box
                  style={{
                    padding: '12px',
                    height: '33%',
                    width: isMobile ? '85%' : '40%',
                  }}
                >
                  <Card
                    elevation={15}
                    onClick={() => updateDashboards(Boards.computerVision)}
                    style={{
                      boxShadow: '0px 0px 6px rgba(0, 255, 0, 0.3)', // Green shadow
                      padding: '12px',
                      width: '100%',
                      height: '500px',
                      cursor: 'pointer',
                      outline: dash.includes(Boards.computerVision)
                        ? `2px solid ${props.theme.palette.primary.dark}`
                        : 'none',
                      background: 'none',
                    }}
                  >
                    <Stack
                      style={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '100%',
                        padding: '12px',
                      }}
                    >
                      <Typography variant={'h5'}>Computer Vision</Typography>
                      <Typography>
                        Real-time AI processed video stream used to calculate
                        your aerodynamic drag. This includes features such as AI
                        contours and image segmentation. Turn this tool on to
                        help guide yourself into a more aerodynamic position.
                      </Typography>
                      <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        style={{
                          padding: '25px',
                          background: 'black',
                          width: '50%',
                          borderRadius: '50%',
                        }}
                      >
                        <source src={ComputerVision} type="video/mp4" />
                      </video>
                    </Stack>
                  </Card>
                </Box>
                <Box
                  style={{
                    padding: '12px',
                    width: isMobile ? '85%' : '40%',
                  }}
                >
                  <Card
                    elevation={15}
                    onClick={() => updateDashboards(Boards.dragLine)}
                    style={{
                      boxShadow: '0px 0px 6px rgba(0, 255, 0, 0.3)', // Green shadow
                      padding: '12px',
                      width: '100%',
                      height: '500px',
                      cursor: 'pointer',
                      outline: dash.includes(Boards.dragLine)
                        ? `2px solid ${props.theme.palette.primary.dark}`
                        : 'none',
                      background: 'none',
                    }}
                  >
                    <Stack
                      style={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '100%',
                        padding: '12px',
                      }}
                    >
                      <Typography variant={'h5'}>Live Data</Typography>
                      <Typography>
                        View live data as it is captured. This tells you how
                        your position and equipment is trending. Currently
                        supporting relative drag values. Coming soon: drag, CdA,
                        power, heart rate, and cadence.
                      </Typography>
                      <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        style={{
                          width: '100%',
                        }}
                      >
                        <source src={DragLine} type="video/mp4" />
                      </video>
                    </Stack>
                  </Card>
                </Box>
                <Box
                  style={{
                    padding: '12px',
                    height: '33%',
                    width: isMobile ? '85%' : '40%',
                  }}
                >
                  <Card
                    elevation={15}
                    // onClick={() => updateDashboards(Boards.Chat)}
                    style={{
                      // boxShadow: '0px 0px 6px rgba(0, 255, 0, 0.3)', // Green shadow
                      padding: '12px',
                      width: '100%',
                      height: '500px',
                      cursor: 'pointer',
                      outline: 'none',
                      background: 'none',
                    }}
                  >
                    <Stack
                      style={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '100%',
                        padding: '12px',
                      }}
                    >
                      <Typography variant={'h5'}>Chat AI</Typography>
                      <Typography>Coming Soon!</Typography>
                      <Chat theme={props.theme} sequence={2} />
                    </Stack>
                  </Card>
                </Box>
              </Box>
            </Stack>
          )}
          {inSession && <AI theme={props.theme} dashboards={dash} />}
        </Container>
      </Box>
    </ThemeProvider>
  );
};
